import './Footer.css';

const Footer = () =>{
  return (
    <div className="footer">
          <div class="footer-links">
            <a href="#home">Home</a>
            <a href="#features">Features</a>
            <a href="#how-it-works">How It Works</a>
            <a href="#resources">Resources</a>
            <a href="#contact">Contact</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Terms of Service</a>
        </div>
        <div class="footer-social">
            <a href="#"><ion-icon name="logo-facebook" class="icon" alt="Facebook"></ion-icon></a>
            <a href="#"><ion-icon name="logo-twitter" class="icon" alt="Twitter"></ion-icon></a>
            <a href="#"><ion-icon name="logo-linkedin" class="icon" alt="LinkedIn"></ion-icon></a>
        </div>
        <div class="footer-contact">
            <p>Email: info@sync.cloud</p>
            <p>Phone: +1 312-678-9371</p>
        </div>
        <div>Subscribe to our newsletter</div>
        <div class="newsletter-signup">
            <input type="email" placeholder="Enter your email"/>
            <button class="cta-btn">Subscribe</button>
        </div>
    </div>
  );
}

export default Footer;
