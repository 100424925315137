import React from 'react';
import './Downloads.css';  // Import the CSS file
import mac_plane from '../../assets/mac-os-logo.png';
import mac_colored from '../../assets/mac-os-logo-colored.png';
import windows_plane from '../../assets/windows.png';
import windows_colored from '../../assets/windows-colored.png';

import setting from '../../assets/settings.png';
import billboard from '../../assets/billboard.png';
import download from '../../assets/download-folder.png';
const platforms = [
  { name: "macOS", icon: mac_colored, downloadLink: "https://sync-macos.s3.us-east-2.amazonaws.com/Sync-1.0.0-arm64.dmg" },
  { name: "Windows", icon: windows_colored, downloadLink: "https://sync-windows.s3.us-east-2.amazonaws.com/Sync+1.0.0.exe" }
];
const highlights = [
    {
      title: "Designed for Cross Platform",
      icon: "🖌️", // Placeholder emoji for the icon, replace with actual SVG/image
      description: "The Sync remote desktop client works seamlessly with any Mac/Windows computer running latest operating system or older versions. Our range of features is constantly updated.",
      buttonText: "",
      link: billboard
    },
    {
      title: "Fast download",
      icon: "⬇️", // Placeholder emoji for the icon, replace with actual SVG/image
      description: "Small but perfectly formed, the Sync remote desktop is fast to download and easy to install. It doesn’t require huge parts of your desktop or server storage.",
      buttonText: "",
      link: download
    },
    {
      title: "Administration",
      icon: "⚙️", // Placeholder emoji for the icon, replace with actual SVG/image
      description: "Access and edit data and settings on remote computers or servers easily with Sync’s remote desktop for Mac. Switch between standard and advanced mode: Limit features or just jump into advanced networking with one click.",
      buttonText: "",
      link: setting
    }
  ];
const Downloads = () => {
  return (
    <div className="downloads-container">
      <h1 className="downloads-header">Train on local, Use any platform.</h1>
      <button className="download-button">
        Download Now
      </button>
      <div className="platforms-grid">
        {platforms.map((platform, index) => (
          <div
            key={index}
            className="platform-tile"
            onClick={() => window.open(platform.downloadLink, "_blank")}
          >
            <img className="platform-icon" src={platform.icon}/>
            <div className="platform-name">{platform.name}</div>
          </div>
        ))}
      </div>
      <div className="highlights-container">
      <div className="highlights-grid">
        {highlights.map((highlight, index) => (
          <div key={index} className="highlight-card">
            <img className="highlight-icon" src={highlight.link}/>
            <h3 className="highlight-title">{highlight.title}</h3>
            <p className="highlight-description">{highlight.description}</p>
            <a href={highlight.link} className="highlight-button">{highlight.buttonText}</a>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Downloads;
