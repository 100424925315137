import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Header from "../../components/Header/Header";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import UserHomePage from "../UserHomePage/UserHomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Link } from 'react-router-dom';
import request from '../../assets/request.png';
import projects_icon from '../../assets/projects.png';
import contribution from '../../assets/contribution.png';
import Home from '../../assets/home.png';
import docs from '../../assets/google-docs.png';
import "./HomePage.css";
import Footer from '../../components/Footer/Footer';
import Dropdown from '../../components/DropDown/DropDown';
import ContributionRequests from '../../views/ContributionRequests/ContributionRequests';
import CreateContributionRequests from '../../views/CreateContributionRequests/CreateContrubutionRequests';
import ProjectListing from '../../views/ProjectListing/ProjectListing';
import Contributions from '../../views/Contributions/Contributions';
import CreateProjectSectionView from "../../views/CreateProjectSectionView/CreateProjectSectionView";
import ProjectDetail from "../../views/ProjectDetail/ProjectDetail";
import Documentation from "../Documentation/Documentation";

const HomePage = ({userDetail, projects, setUserDetail, setProjects,fetchProjects}) => {
  const [dropdownVisible, setBackgroundblur] = useState(false);


  const categoriesDropdown = [
    "Healthcare", "Finance", "IoT Devices", "Mobile and Edge Computing", 
    "Telecommunications", "Retail and E-commerce", "Manufacturing", 
    "Education", "Autonomous Vehicles", "Social Media and Advertising"
  ];
  

  const citiesDropdown = [
    "New York", "Los Angeles", "Chicago", "Houston", "Phoenix", 
    "Philadelphia", "San Antonio", "San Diego", "Dallas", "San Jose"
  ];

  return (
      <div className="homepage">
        {/* <Header userDetail={userDetail} setUserDetail={setUserDetail} setProjects={setProjects} setBackgroundblur={setBackgroundblur} fetchProjects={fetchProjects}/> */}
        {!userDetail.token ? (
          <div className="homepage_main_container" style={dropdownVisible && projects.length >0  ? {backgroundColor: 'rgba(0, 0, 0, 0.4)'} : {backgroundColor: 'unset'}}>
            {projects.length >0  ? <div className="homepage_main_container_listing">
              <div className="homepage_main_master_text1">
              Train, Deploy and Monitor Your Federated Models.
            </div>
            </div> : null}
            {/* <Link to={'/documentation'}>Documentation V1.0</Link> */}
                <a className="documentation_button" href="https://syncpro.cloud/documentation" target="_blank" rel="noopener noreferrer">
                <img className="documentation_button_icon" src={docs}/>
                <span className="documentation_button_text">Documentation V1.0</span>
                </a>
            {projects.length >0  ? <div className="homepage_main_container_listing">
              <div className="homepage_main_master_text2">
                Currently Ongoing Projects in your Area
              </div>
            </div> : null}

            {projects.length >0 ? <div className="homepage_main_container_project_listing">
              {projects.map((item, idx) => (
                <ProjectCard key={idx} item={item} />
              ))}
            </div> : <div className="homepage_no_results">Loading....</div>}

            <Footer />
          </div>
        ) : (
          <div className="homepage-container-body">
            <div className="homepage-sidenav">
              <ul>
                <Link to={'/'}>
                  <li>
                    <img className='sidenav_item_icon' src={Home} />
                    <span className="sidenav_item_text">Home</span>
                  </li>
                </Link> 
                <Link to={'/contribution-requests'}>
                  <li>
                    <img className='sidenav_item_icon' src={request} />
                    <span className="sidenav_item_text">Requests</span>
                  </li>
                </Link> 
                <Link to={'/ongoing-projects-list'}>
                  <li>
                    <img className='sidenav_item_icon' src={projects_icon} />
                    <span className="sidenav_item_text">Projects</span>
                  </li>
                </Link> 
                <Link to={'/your-contributions'}>
                  <li>
                    <img className='sidenav_item_icon' src={contribution} />
                    <span className="sidenav_item_text">Contributions</span>
                  </li>
                </Link> 
              </ul>
            </div>
            <Routes>
            <Route
                path="/"
                element={<UserHomePage userDetail={userDetail} />}
              />
              <Route
                path="/create-project"
                element={<CreateProjectSectionView userDetail={userDetail} />}
              />
              <Route
                path="/contribution-requests"
                element={<ContributionRequests userDetail={userDetail} />}
              />
              <Route
              exact
                path="/create-contribution-request"
                element={<CreateContributionRequests userDetail={userDetail} />}
              />
              <Route
                path="/ongoing-projects-list"
                element={<ProjectListing userDetail={userDetail} />}
              />
              <Route
                path="/your-contributions"
                element={<Contributions userDetail={userDetail} />}
              />
              <Route
                path="/documentation"
                element={<Documentation userDetail={userDetail} />}
              />
              </Routes>
          </div>
        )}
      </div>
  );
};

export default HomePage;
