import React from 'react';
import './UserHomePage.css';
import ProjectListingSectionView from '../ProjectListingSectionView/ProjectListingSectionView';
import RecentContributionsSectionView from '../RecentContributionsSectionView/RecentContributionsSectionView';
import { useNavigate } from 'react-router-dom';
import ContributionRequestsSectionView from '../ContributionRequestsSectionView/ContributionRequestsSectionView';
const UserHomePage = ({userDetail}) => {
  const navigate = useNavigate();

  const handleCreateProject = () => {
    navigate('/create-project');
  };

  return (
    <div className="user_home_page">
      <div className='user_home_page_first _section1'>
          <ProjectListingSectionView onCreateProject={handleCreateProject} userDetail = {userDetail}/>
          <RecentContributionsSectionView userDetail = {userDetail}/>
      </div>
      <div className='user_home_page_first_section2'>
          <ContributionRequestsSectionView userDetail = {userDetail}/>
      </div>
    </div>
  );
};

export default UserHomePage;
