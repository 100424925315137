import React, { useState, useEffect } from 'react';
import './CountDownTimer.css'
const CountDownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = new Date(targetDate) - now;

    if (difference <= 0) {
      return null;
    }

    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft();
      setTimeLeft(updatedTimeLeft);

      if (!updatedTimeLeft) {
        clearInterval(timer); // Stop the timer if the event is finished
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  if (!timeLeft) {
    return <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Event Finished</div>;
  }

  return (
    <div className='countdown_timer_container' style={{ fontSize: '0.7rem', fontWeight: 'bold', textAlign: 'center' }}>
      <span>{timeLeft.days}</span> days : <span>{timeLeft.hours}</span> hours : <span>{timeLeft.minutes}</span> minutes : <span>{timeLeft.seconds}</span> seconds
    </div>
  );
};

export default CountDownTimer;
