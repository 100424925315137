import React from "react";

const Documentation = () => {
  const sections = [
    {
      title: "Introduction",
      content: "Sync is a Federated Learning Platform for collaborative machine learning. It allows users to create and contribute to projects while maintaining data privacy.",
    },
    {
      title: "Home Page",
      content: "The home page serves both host users and contributor users. Users need to sign up and log in to perform any activities.",
    },
    {
      title: "Dashboard",
      content: "After logging in, users access the dashboard. Hosts can create new projects, and contributors can search for projects to contribute to.",
    },
    {
      title: "Creating a Federated Learning Project",
      content:
        "Hosts can create a new project by providing project details. An initial model (.h5) file will be created and stored on AWS S3.",
    },
    {
      title: "Searching and Contributing to Projects",
      content:
        "Contributors can search for projects using filters by location and category. Once a suitable project is found, contributors can send a contribution request.",
    },
    {
      title: "Contribution Approval Process",
      content:
        "Hosts receive contribution requests and decide whether to approve or reject them. Approved contributors receive a code for local training.",
    },
    {
      title: "Using the Desktop Client",
      content:
        "Contributors download and run the client software. They import the approved project using the hash code and fetch basic project details.",
    },
    {
      title: "Training and Syncing",
      content:
        "Contributors download the model (.h5) file and upload their training data to start training. After training, weights and biases are sent back to the server for aggregation.",
    },
    {
      title: "Server Aggregation",
      content:
        "The server averages the weights and biases with the existing model parameters, ensuring the updated model reflects contributions from all users.",
    },
  ];

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Sync: A Federated Learning Platform</h1>
      <p>
        This documentation provides a detailed overview of the platform, including its
        features, user workflows, and technical aspects.
      </p>
      <div>
        {sections.map((section, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <h2>{section.title}</h2>
            <p>{section.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Documentation;
