import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import './Header.css';
import avtar from '../../assets/account.png';
import Auth from '../Auth/Auth';
import { Link } from 'react-router-dom';
import Dropdown from '../../components/DropDown/DropDown';
import axios from 'axios';  // Add axios for API requests

const Header = ({ userDetail, setUserDetail,setProjects,setBackgroundblur,fetchProjects }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [authPopupVisible, setAuthPopupVisible] = useState(false);
  const [authType, setAuthType] = useState('');
  const [selectedSearchCategory, setSelectedSearchCategory] = useState('All');
  const [searchValue, setSearchValue] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredProjectsFromBackend, setFilteredProjectsFromBackend] = useState([]);  // New state to store projects from backend
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const navigate = useNavigate();

  const categoriesDropdown = [
    "Healthcare", "Finance", "IoT Devices", "Mobile and Edge Computing", 
    "Telecommunications", "Retail and E-commerce", "Manufacturing", 
    "Education", "Autonomous Vehicles", "Social Media and Advertising"
  ];

  const citiesDropdown = [
    "Milwaukee","New York", "Los Angeles", "Chicago", "Houston", "Phoenix", 
    "Philadelphia", "San Antonio", "San Diego", "Dallas", "San Jose"
  ];

  const searchCategoriesDropdown = [
    "All", "Projects", "Users", "Organizations"
  ];

  const projectNames = [
    "Federated Healthcare System", "Autonomous Vehicle Platform", 
    "Smart City Traffic Management", "IoT Energy Optimization", 
    "Retail Predictive Analytics", "Education Recommendation System",
    "Finance Fraud Detection", "Telecom Network Optimization", "Personalized Medicine"
  ];

  const toggleDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };
  

  const handleSignOut = () => {
    Cookies.remove('token');
    Cookies.remove('username');
    Cookies.remove('email');
    Cookies.remove('user_id');

    const obj = { token: '', name: '', email: '', user_id: '' };
    setUserDetail(obj);
    setDropdownVisible(false);
  };

  const handleAuthPopUp = (type) => {
    setAuthType(type);
    setAuthPopupVisible(true);
  };

  // Fetch matching projects from the backend
  const fetchMatchingProjects = async (searchTerm) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SYNC_BACKEND_API_BASE_URL}/projects`, {
        params: { project_name: searchTerm }  // Pass search term as query parameter
      });
      setProjects(response.data.projects);  // Store fetched projects
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  // Update search value and fetch matching projects from the backend
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value.length > 0) {
      const filtered = projectNames.filter(project =>
        project.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProjects(filtered);
      setIsDropdownOpen(true);
      setBackgroundblur(true)
      // Fetch matching projects from backend
      // fetchMatchingProjects(value);
    } else {
      setIsDropdownOpen(false);
      setBackgroundblur(false);
      fetchProjects();
    }
  };

  const handleProjectSelect = (project) => {
    setSearchValue(project);
    setIsDropdownOpen(false);
    fetchMatchingProjects(project);
  };
  const popupRef = useRef(null);



  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="header">
      <div className='header_company_text'>
        <Link to={'/'}>Sync</Link>
        <div className='text_brand'>A Platform for Federated Learning</div>
      </div>
      <div className="homepage_main_container_location_search">
        <div className="autocomplete-container">
          <input
            placeholder={`Search in ${selectedSearchCategory}`}
            className="homepage_main_container_search_input"
            value={searchValue}
            onChange={handleSearchChange}
          />
          {isDropdownOpen && (
            <div className="autocomplete-dropdown">
              {filteredProjects.length > 0 ? (
                filteredProjects.map((project, index) => (
                  <div 
                    key={index} 
                    className="autocomplete-item" 
                    onClick={() => handleProjectSelect(project)}
                  >
                    {project}
                  </div>
                ))
              ) : (
                <div className="autocomplete-item">No results found</div>
              )}
            </div>
          )}
        </div>
        <div className="homepage_main_container_search">
          <Dropdown 
            list={searchCategoriesDropdown} 
            onSelect={(selected) => setSelectedSearchCategory(selected)} 
          />
        </div>
        <div className="homepage_main_container_location">
          <Dropdown list={citiesDropdown} />
        </div>
        <div className="homepage_main_container_category">
          <Dropdown list={categoriesDropdown} />
        </div>
      </div>

      {!userDetail.token ?
        <div className='header_auth'>
                <div className='header_downloads'>
        <Link to={'/downloads'}><div className='header_downloads_button'>Downloads</div></Link>
      </div>
          <div className='header_auth_signin' onClick={() => handleAuthPopUp('SignIn')}>Sign In</div>
          <div className='header_auth_signup' onClick={() => handleAuthPopUp('SignUp')}>Sign Up</div>
        </div>
        :
        <div className='header_user_detail' onClick={toggleDropdown}>
          <div className='header_username'onClick={toggleDropdown} >{userDetail.name}</div>
          <div className='header_avtar_container' onClick={toggleDropdown}>
            <img onClick={toggleDropdown} className='header_avtar' src={avtar} alt="User Avatar" />
          </div>
          {dropdownVisible && (
            <div className="dropdown_menu" ref={popupRef}>
              <div className="dropdown_item">Profile</div>
              <div className="dropdown_item">Dashboard</div>
              <div className="dropdown_item" onClick={handleSignOut}>Sign Out</div>
            </div>
          )}
        </div>
      }
      {authPopupVisible && (
        <Auth initialType={authType} setAuthPopupVisible={setAuthPopupVisible} userDetail={userDetail} setUserDetail={setUserDetail} />
      )}
    </div>
  );
};

export default Header;
